<template>
  <b-modal
    id="modal-show-secret"
    ref="modal-show-secret"
    footer-class="flex-nowrap"
    no-close-on-backdrop
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.twoFactorEnabled.title') }}</h3>
    </template>

    <template #default>
      <div v-if="!successfulSetup">
        <div class="text-center  mb-3 p-4">
          <div class="mx-5 pt-3 text-center">
            <p class="mb-1">{{ $t('modals.twoFactorEnabled.desc') }}</p>
          </div>
          <div v-if="qrCode">
            <img width="250" height="250" :src="qrCode" alt="">
          </div>
          <div>

            <p v-b-toggle.collapse-2 class="m-0 ">{{ $t('modals.twoFactorEnabled.secretCode') }}
            <b-icon icon="chevron-down" aria-hidden="true"></b-icon></p>
            <!-- Element to collapse -->
            <b-collapse id="collapse-2">
              <div class="pt-1">
                <b-form-input
                size="sm"
                class="mx-auto text-center"
                v-model="secretCode"
              >
              </b-form-input>
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="mx-5 mb-5 text-center">
          <p for="value-name"><strong>
            {{ $t('modals.twoFactorEnabled.totpInput') }}
          </strong></p>
          <b-form-input
            id="entry-name"
            class="mx-auto text-center"
            v-model="totpPassword"
            autofocus
            max=6
            debounce="500"
            @keyup.enter="enterPressed"
            @update="showErrorMsg = false"
          >
          </b-form-input>
          <div
            v-if="showErrorMsg"
            class="text-danger mt-1"
          >
            <em>{{ $t('modals.twoFactorEnabled.error') }}</em>
          </div>
        </div>
      </div>
      <div v-else class="success-icon text-center px-2">
        <b-icon icon="check-circle" variant="success" class="mt-5 mb-4"></b-icon>
        <p class="mt-2 mb-5">{{ $t('modals.twoFactorEnabled.successMsg') }}</p>
      </div>
    </template>
    <template #modal-footer>
      <div v-if="!successfulSetup">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('cancel-two-factor')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="!isValidated"
          variant="primary"
          class="p-3"
          block
          @click="enableTwoFactor"
        >
        {{ $t('modals.twoFactorEnabled.enableButton') }}
        </b-button>
      </div>
      <div v-else>
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('enable-two-factor')"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import Auth from '@aws-amplify/auth';
import QRCode from 'qrcode';

export default {
  name: 'EnableTwoFactorAuth',
  props: {
    authUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      qrCode: null,
      secretCode: null,
      totpPassword: null,
      successfulSetup: false,
      showErrorMsg: false,
    };
  },
  computed: {
    isValidated() {
      return this.totpPassword && this.totpPassword.length > 0;
    },
  },
  async created() {
    this.secretCode = await Auth.setupTOTP(this.authUser);
    const userName = this.authUser.username;
    const issuer = 'webmag.io';
    const secretQrCodeUrl = `otpauth://totp/Webmag:${userName}?secret=${this.secretCode}&issuer=${issuer}`;
    this.qrCode = await QRCode.toDataURL(secretQrCodeUrl);
  },
  mounted() {
    this.$bvModal.show('modal-show-secret');
  },
  methods: {
    enterPressed() {
      if (this.isValidated) {
        this.enableTwoFactor();
      }
    },
    enableTwoFactor() {
      if (this.isValidated) {
        this.setLoadingIndicator(true);
        console.log('EnableTwoFactorAuth enableTwoFactor');
        Auth.verifyTotpToken(this.authUser, this.totpPassword)
          .then(() => {
            Auth.setPreferredMFA(this.authUser, 'TOTP')
              .then(() => {
                // show the success message
                this.successfulSetup = true;
                this.setLoadingIndicator(false);
              })
              .catch((err) => {
                console.error('EnableTwoFactorAuth setPreferredMFA error', err);
                this.showErrorMsg = true;
                this.setLoadingIndicator(false);
              });
          })
          .catch((err) => {
            console.error('EnableTwoFactorAuth verifyTotpToken error', err);
            this.showErrorMsg = true;
            this.setLoadingIndicator(false);
          });
      }
    },
    setLoadingIndicator(isLoading) {
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: isLoading,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">

#entry-name{
  max-width:200px;
}
/deep/ .disabled {
  cursor: not-allowed;
}
/deep/ .modal-footer > div {
  display: flex;
  width: 100%;
  margin: 0;
}
.success-icon svg {
  font-size: 60px;
}
</style>
