var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-show-secret",
    attrs: {
      id: "modal-show-secret",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [
            _c("h3", [_vm._v(_vm._s(_vm.$t("modals.twoFactorEnabled.title")))])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            !_vm.successfulSetup
              ? _c("div", [
                  _c("div", { staticClass: "text-center mb-3 p-4" }, [
                    _c("div", { staticClass: "mx-5 pt-3 text-center" }, [
                      _c("p", { staticClass: "mb-1" }, [
                        _vm._v(_vm._s(_vm.$t("modals.twoFactorEnabled.desc")))
                      ])
                    ]),
                    _vm.qrCode
                      ? _c("div", [
                          _c("img", {
                            attrs: {
                              width: "250",
                              height: "250",
                              src: _vm.qrCode,
                              alt: ""
                            }
                          })
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.collapse-2",
                                modifiers: { "collapse-2": true }
                              }
                            ],
                            staticClass: "m-0"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("modals.twoFactorEnabled.secretCode")
                              ) + " "
                            ),
                            _c("b-icon", {
                              attrs: {
                                icon: "chevron-down",
                                "aria-hidden": "true"
                              }
                            })
                          ],
                          1
                        ),
                        _c("b-collapse", { attrs: { id: "collapse-2" } }, [
                          _c(
                            "div",
                            { staticClass: "pt-1" },
                            [
                              _c("b-form-input", {
                                staticClass: "mx-auto text-center",
                                attrs: { size: "sm" },
                                model: {
                                  value: _vm.secretCode,
                                  callback: function($$v) {
                                    _vm.secretCode = $$v
                                  },
                                  expression: "secretCode"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "mx-5 mb-5 text-center" },
                    [
                      _c("p", { attrs: { for: "value-name" } }, [
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("modals.twoFactorEnabled.totpInput")
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("b-form-input", {
                        staticClass: "mx-auto text-center",
                        attrs: {
                          id: "entry-name",
                          autofocus: "",
                          max: "6",
                          debounce: "500"
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.enterPressed.apply(null, arguments)
                          },
                          update: function($event) {
                            _vm.showErrorMsg = false
                          }
                        },
                        model: {
                          value: _vm.totpPassword,
                          callback: function($$v) {
                            _vm.totpPassword = $$v
                          },
                          expression: "totpPassword"
                        }
                      }),
                      _vm.showErrorMsg
                        ? _c("div", { staticClass: "text-danger mt-1" }, [
                            _c("em", [
                              _vm._v(
                                _vm._s(_vm.$t("modals.twoFactorEnabled.error"))
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _c(
                  "div",
                  { staticClass: "success-icon text-center px-2" },
                  [
                    _c("b-icon", {
                      staticClass: "mt-5 mb-4",
                      attrs: { icon: "check-circle", variant: "success" }
                    }),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        _vm._s(_vm.$t("modals.twoFactorEnabled.successMsg"))
                      )
                    ])
                  ],
                  1
                )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            !_vm.successfulSetup
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3 border-right-light-grey",
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("cancel-two-factor")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3",
                        attrs: {
                          disabled: !_vm.isValidated,
                          variant: "primary",
                          block: ""
                        },
                        on: { click: _vm.enableTwoFactor }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("modals.twoFactorEnabled.enableButton")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3 border-right-light-grey",
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("enable-two-factor")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                    )
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }